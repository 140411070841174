import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const contattiResponsePage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Modulo Ok" />
    <Container className="py-4">
      <div className="py-5">
        <Row className="py-5 my-5">
          <Col>
            <div className="h5 text-center">
              Modulo inviato Correttamente
              <p className="mt-2">
                <small>
                  <Link to="/" className="link-no-style">
                    torna alla home
                  </Link>
                </small>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </Layout>
)

export default contattiResponsePage
